function getRandomImage(topic) {
  const imgWidth = [1440, 1024, 768, 544];
  const imgHeight = [1080, 1024, 768, 544];

  const randomWidth = imgWidth[Math.floor(Math.random() * imgWidth.length)];
  const randomHeight = imgHeight[Math.floor(Math.random() * imgHeight.length)];

  // can use `?random=${i}` to refresh
  return `https://loremflickr.com/${randomWidth}/${randomHeight}/${topic}`;
}

// Restituisce il cookie con il nome dato,
// o undefined se questo non esiste
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // aggiungi altri percorsi di default se necessario
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

module.exports = { getRandomImage, getCookie, setCookie, deleteCookie };
