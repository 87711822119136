/******************************************************************************
 * Templates / Home
 *****************************************************************************/

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import { getCookie, setCookie, deleteCookie } from '../../../src/scripts/utils';

gsap.registerPlugin(ScrollTrigger);
const LOGO_SHOW_UP_DURATION = 2000;

window.addEventListener('apus:init', () => {
  const isHome = document.querySelector('body.home');
  const websiteLoading = document.querySelector('.ap-website-loading');
  const logoContainer = document.querySelector(
    '.ap-website-loading > .container'
  );
  const html = document.querySelector('html');
  let panels;

  window.addEventListener('scroll', function () {
    var scrollTop = window.scrollY;

    var headerOverlay = document.querySelector('.header-overlay');
    headerOverlay.style.opacity =
      1 - (headerOverlay.clientHeight - scrollTop) / headerOverlay.clientHeight;
  });

  if (isHome) {
    if (getCookie('navigationFrom') === 'internal') {
      // in-internal navigation
      websiteLoading.classList.add('skip-animation');
      manageSafariOverflow();
      deleteCookie('navigationFrom');
    } else {
      // from-external or refreshing navigation
      window.onload = function () {
        setTimeout(() => window.scrollTo(0, 0));
      };
      websiteLoading.classList.remove('skip-animation');
      manageAnimation();
    }
  }

  function manageAnimation() {
    const title = document.querySelector('.ap-website-loading .ap-title');
    logoContainer.style.minWidth =
      Math.max(logoContainer.clientWidth + 10, 275) + 'px';
    const toHide = document.querySelectorAll('[class^="hide"]');
    disableBodyScroll(isHome);
    // Just to avoid wrong initial state
    setTimeout(() => {
      title.classList.add('ap-title--small');
    });

    title.addEventListener('animationstart', () => {
      setTimeout(() => {
        toHide.forEach((item) => {
          item.style.overflow = 'hidden';
        });
      }, LOGO_SHOW_UP_DURATION);
    });

    // logoContainer.addEventListener('animationstart', (animation) => {
    //   if (animation.animationName == 'move-title') {
    //     logoContainer.style.width = title.clientWidth + 'px';
    //     console.log(logoContainer.style.width);
    //   }
    // });

    logoContainer.addEventListener('animationend', (animation) => {
      if (animation.animationName == 'move-title') {
        enableBodyScroll(isHome);
        manageSafariOverflow();
      }
    });

    toHide.forEach((item) => {
      item.addEventListener('animationend', (animation) => {
        if (animation.animationName == 'shrink-letters') {
          // console.log(title.style.width);
        }
      });
    });
  }

  function manageSafariOverflow() {
    html.style.overflowX = 'hidden';
  }

  function initialize() {
    panels = gsap.utils.toArray('.panel');
    // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
    let tops = panels.map((panel) =>
      ScrollTrigger.create({ trigger: panel, start: 'top top' })
    );

    panels.forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: () =>
          panel.offsetHeight < window.innerHeight ? 'top top' : 'bottom bottom', // if it's shorter than the viewport, we prefer to pin it at the top
        pin: true,
        pinSpacing: false,
        // onEnter: (self) => {
        //   // console.log(self);
        //   if (self.trigger.classList.contains('main-body')) {
        //     header.classList.add('custom-height');
        //   }
        // },
        // onEnterBack: (self) => {
        //   // console.log(self);
        //   if (self.trigger.classList.contains('ap-c-header')) {
        //     header.classList.remove('custom-height');
        //   }
        // },
      });
    });
  }

  function destroy() {
    panels = null;
  }

  window.APUS.register('templates-home', initialize, destroy);

  initialize();
});
